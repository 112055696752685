$(function(){
    // ACCORDION
    $('.acc header').click(function(){
        var parent = $(this).parent();
        parent.siblings().find('footer').hide();
        parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('footer').stop().slideToggle();
    });


    // ACCORDION Toggle 2
    $('.listBlock a.cta').click(function(){
        var parent = $(this).parent();
        parent.find('.content .details').slideToggle();
    });

    // Search Bar Functionality
    var searchBtn = $('.search .searchBtn');

    searchBtn.click(function(){
        $(this).closest('.search').toggleClass('searchActive');
        $(this).siblings('.searchBox').find('form input[type="text"]').focus();
    });


    // Equalize product block heights
    $('.productBlocks, .gridBlocks').imagesLoaded(function(){
        $(window).resize(function(){
            equalize($('.productBlocks:not(.gridBlocks):not(.threeBlock)'), $('.product') );
            equalize($('.gridBlocks:not(.gridBlocks):not(.threeBlock)'), $('.item') );
        }).resize();
    });


    // If prevous page is 
    $('.previousPage').each(function(){
        if (document.referrer == window.location.origin) {
            $(this).show();
            $(this).click(function(event){
                event.preventDefault();
                window.history.back();
            });
        } else {
            $(this).hide();
        }
    });

    // Team info panels
    var teamProfile = $('.teamMeetup .profile .action');

    teamProfile.click(function(){
        var parent = $(this).parent();

        if ( parent.hasClass('active') ) {
            parent.removeClass('active');
            parent.siblings().removeClass('disabled');
        } else {
            parent.addClass('active').siblings().addClass('disabled');
        }
    });
});


// Latest NEW Slider
$(function() {
    var mainCarousel = $('div.ticker');

    mainCarousel.imagesLoaded(function(){
        mainCarousel.flickity({
            cellAlign: 'center',
            groupCells: true,
            contain: true,
            prevNextButtons: true,
            pageDots: false,
            autoPlay: false,
            wrapAround: true,
            arrowShape: { 
                x0: 20,
                x1: 55, y1: 50,
                x2: 60, y2: 50,
                x3: 25
            }
        });
    });

});