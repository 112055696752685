$(function(){  
    
    // MOBILE HEADER MENU
    var $menuNav = $('.siteHeader #mainNav').clone();
    $menuNav.attr( 'class', 'mobile-nav' );

    // Add New mmenu script full width
    $menuNav.mmenu({
        extensions : [ "shadow-panels", "fx-panels-slide-100", "border-none", "theme-black", "fullscreen" ],        
        offCanvas   : {
        position    : "right"
    },
    
    navbars: {
        content : [ "prev", "title", "next", "close"],
        height  : 1,
    },    
                
    setSelected: true,
    searchfield: {
        resultsPanel: true
    }}, { });
    
	/*$menuNav.mmenu({
        offCanvas: {position: "right"}
	});*/
    
    $('.mm-panel').append( $('#subNav ul.l0').clone().addClass('sub-menu') );

	var API = $('.mobile-nav').data( "mmenu" );

	$(".toggle").click(function() {
		API.open();
	});

	// If the window width id greater than close mmenu
	$(window).resize(function(){
		if( $(window).width() > 900 ){
			API.close();
		}
	});

    // fade Page ELements in
    var $animation_elements = $('section');
    var $window = $(window);

    function check_if_in_view(el, callback) {
        var window_height = $window.height();
        var window_top_position = $window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);

        $.each(el, function() {
            var $element = $(this);
            var element_height = $element.outerHeight();
            var element_top_position = $element.offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ( (element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position) ) {
                $element.addClass('in-view');

                if(typeof callback == 'function'){
                    callback($element);
                }
            }
        });
    }
});