$(function(){
    
    // TABS SHIFT ACTIVE CLASS
    var tabs = $('ul.tabs li');

    // FILTER PRODUCT BLOCK

    tabs.click(function(){
        $(this).addClass('active').siblings().removeClass('active');
        
        var filterName = $(this).data('filter');
        var filterable = $(".tab-filter-details").find('div[data-type]');
        
        if(filterName != 'all'){
            
            filterable.filter(':not([data-type*="'+filterName+'"])').addClass('hide');
            filterable.filter('[data-type*="'+filterName+'"]').removeClass('hide');
            
            if (!filterable.filter('[data-type*="' + filterName + '"]').hasClass('is-selected')){
                var filterindex = filterable.filter('[data-type*="' + filterName + '"]').index();
                stockSlider.flickity('selectCell', filterindex);
            }
        } 

        else {
            filterable.removeClass('hide');
        }
    });

});