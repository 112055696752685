$(function(){

    var player;

    if($('.videoBG').length){
        var isMobile = {
            Android: function() {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
            },
            any: function() {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
            }
        };

        var videoBg = $('.videoBG'),
            videoThumb = $('#videoThumb'),
            dt = new Date(),
            time = dt.getSeconds(),
            VidID = videoBg.attr('data-video');

        // DONT REMOVE THE TILDE EXPRESSION NOOBS
        if ( ~VidID.indexOf('youtube') ) {

            // YOUTUBE SCRIPT - DYNAMICALLY ADD YOUTUBE SCRIPT
            var tag = document.createElement('script');
            tag.src = "//www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            var videoID = VidID != undefined ? VidID.split('v=').pop() : '';

            // YOUTUBE VIDEO CREATOR FUCNTION
            var ytVideos = {};

            $(window).load(function(){
                youtubeCreator(videoID);
            });

        } else {
            $(videoBg).attr('id','wistia_'+ VidID);
    
            if(isMobile.any()){
                $(videoBg).hide();
                $(videoThumb).hide();
            }
    
            var embedVideo = function() {
                var videoOptions = {
                    backgroundvideo: VidID,
                    backgroundvideoDiv: videoThumb
                };
                var supportsHd;
                if (bandwidthTest.isFresh()) {
                    supportsHd = bandwidthTest.savedResult().supportsHd;
                } else {
                    supportsHd = bandwidthTest.supportsHd();
                }
                if (supportsHd) {
                    videoOptions.videoQuality = 'hd-only';
                } else {
                    videoOptions.videoQuality = 'md';
                }
                Wistia.obj.merge(videoOptions, {
                    plugin: {
                    cropFill: {
                        src: "//fast.wistia.com/labs/crop-fill/plugin.js"
                    }
                }
            });
    
            wistiaEmbed = Wistia.embed(VidID, videoOptions);
                wistiaEmbed.bind("play", function(){
                    // wistiaEmbed.pause();
                    wistiaEmbed.time(0);
                    $("#wistia_"+VidID).css('visibility', 'visible');
                    wistiaEmbed.play();
                    return this.unbind;
                    });
                };
    
            var bandwidthTest = new Wistia.BandwidthTest({
                timeoutLength: 2000, // if the test file takes longer than this time to DL, the test failed. The default file is exactly 1MB.
                freshFor: 3600000 // don't do the bandwidth test for another hour
            });
    
            if(!Modernizr.touch){
                if (bandwidthTest.isFresh()) {
                    setTimeout(function() {
                    embedVideo();
                }, 10);
                } else {
                    bandwidthTest.bind("ended", embedVideo);
                    bandwidthTest.start();
                }
            }
        }
    }


    // YOUTUBE VIDEO
    function youtubeCreator(playerID, where, index) {
        var i = index == undefined ? ytVideos.length + 1 : index;
    
        createVideoBox(playerID);

        ytVideos[playerID] = new YT.Player(playerID, {
            width: 1920,
            height: 1080,
            videoId: playerID,
            playerVars: {
                'autohide': 1,
                'controls': 0,
                'disablekb': 1,
                'loop': 1,
                'modestbranding':1
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });

        // Player state wrapper
        function onPlayerStateChange(event) {
            switch(event.data) {
            case 0:
                // record('video ended');
                event.target.playVideo();
            break;
            case 1:
                // Play video ----
                // console.log('video playing from '+yVideos[localVidID].getCurrentTime());
                player.fadeIn(300);
            break;
            case 2:
                console.log('video paused at '+ytVideos[playerID].getCurrentTime());
            }
        }

        function onPlayerReady(event){
            console.log(event);
            event.target.playVideo();
            ytVideos[playerID].mute();
        }

        $('.'+playerID).fitVids();
    }

    // Create Video Lightbox
    function createVideoBox(videoID){
        var playerMarkUp = ('<div class="player '+videoID+'"><span id="'+videoID+'"></span></div>');
        $('.vidWrap').append(playerMarkUp);
        // Responsively Cover Banner
        var par = videoBg.parent();
        player = par.find('.player.'+videoID);
        player.hide();
        var aspect = 100/56.25;
        $(window).resize(function(){
            var w = par.width(),
                h = par.height();
            if (w/h < aspect){
                // SQ   
                player.css({
                    'width':h*aspect+'px'
                });
                console.log(player);
            } else{
                player.css({
                    'width':'100%'
                })
            }
        }).resize();
    }


});
