$(function(){
    
    // Stock Slider - HomePage
    $('.banner').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.slideSet').children('.slide').clone();

            scope.find('.slideSet').flickity({
                cellAlign: 'right',
                initialIndex: 0,
                groupCells: '100%',
                pageDots: true,
                autoPlay: 5000,
                prevNextButtons: true,
            });            
        });
    });


    // Our History Slider - About Page
    $('.historySlider').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.slideSet').children('.items').clone();

            scope.find('.slideSet').flickity({
                cellAlign: 'right',
                initialIndex: 0,
                groupCells: '100%',
                pageDots: true,
                prevNextButtons: false,
            });            
        });
    });


    // Thumbnail Slider - LED Product Details Page
    $('.thumbnailSlider').each(function(){
        var scope = $(this);

        // 1st carousel, main
        $('.larg-slider').flickity({
            pageDots: false,
            prevNextButtons: false,
        });

        // 2nd carousel, navigation
        $('.larg-slider-nav').flickity({
            asNavFor: '.larg-slider',
            contain: true,
            pageDots: false,
            prevNextButtons: false,
        });
    });

    // About Section Slider - HomePage
    $('.orderBlock').each(function(){
        var scope = $(this);
        $(this).imagesLoaded(function(){
            
            var stockItems = $('.fiveRow').children('.blocks').clone();            
            
            if ( matchMedia('screen and (max-width: 992px)').matches ) {
                scope.find('.fiveRow').flickity({                
                    initialIndex: 0,
                    contain: true,
                    cellAlign: 'right',                
                    wrapAround: false, 
                    draggable: true,
                    groupCells: true,
                    pageDots: false,
                    prevNextButtons: true,                    
                });
            }            

        });
    });
    
    
});